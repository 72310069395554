<template>
  <v-card>
    <v-card-title>
      <v-toolbar flat dense>
      <programDialog/>
      <v-toolbar-title> Programs</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" placeholder="Search here"
        >Search here</v-text-field
      >
      <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
    </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="program"
        :items-per-page="5"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon small class="mr-2" color="primary" @click="editItem(item)">
            mdi-pencil
          </v-icon> -->
          <v-icon small color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { sync,dispatch } from "vuex-pathify";
import programDialog from './programDialog.vue'

export default {
  name:"Programs",
  data() {
    return {
      dialogDelete:false,
      search: "",
      itemid:'',
      headers: [
        {
          text: "Programs",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Actions", value: "actions", sortable: "false" },
      ],
    };
  },
  components:{
    programDialog
  },

  beforeMount(){
    this.loadData()
  },
 
  computed:{
    ...sync('program',['programDialog','program'])
  },
  methods:{
    async loadData(){
       await dispatch('program/loadData')
    },
    editItem(){
      this. programDialog=true

    },
    deleteItem(item){
      this.itemid=item
      this.dialogDelete=true
    },
    deleteItemConfirm() {
      dispatch('program/deleteProgram',this.itemid)
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    deleteBtn(val) {
      val || this.closeDelete();
    },

    

  }
}
</script>